
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap');


.home-container{
    height: 75%;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.home-container::-webkit-scrollbar {
    width: 0em;
  }

.home-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  }




.intro-container{
  width: 100%;
  margin-top: 2rem;
}

.intro-container h3{
  margin: 0;
  font-size: 2rem;
  margin-left: 3.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
.intro-container p{
  width: 90%;
  margin-left: 5%;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  letter-spacing: 0.1em;
}

.features-container{
  width: 100%;
  /* background-color: aquamarine; */
  margin-top: 2rem;
}

.features-container>h3{
  margin: 0;
  font-size: 2rem;
  margin-left: 3.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.features-wrapper{
  width: 90%;
  /* background-color: bisque; */
  margin-left: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.feature-container{
  margin: 10px;
 box-shadow: 0px 15px 15px gray;
 display: flex;
 flex-direction: column;
 align-items: center;
}

.feature-container img{
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
}

.feature-container h3{
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
}


.btn{
  margin-top: 1rem;
    width: 100%;
    grid-column: 2/3;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.btn a{
  text-decoration: none;
  color: white;

}

.btn>button{
  padding: 0.5rem 2rem;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: white;
  background-color: #3C6255;
  border: 0;
}


@media only screen and (max-width:480px) {
 

  .intro-container h3{
    margin-left: 1rem;
  }

  .features-container>h3{
    margin-left: 1rem;
    margin-bottom: 1.5rem;
  }

  .features-wrapper{
    margin-left: 0;
    width: 100%;
    grid-template-columns: 1fr;
  }

  .feature-container{
    width: 100%;
    margin-left: 0px;
  }

  .btn{
    grid-column: 1/1;
  }
}