.navbar-container{
    width: 100%;
    height:5.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.sideLayer_Navbar{
    width: 5%;
    height: 20px;
}

.sideLayer_Navbar hr{
 height: 30%;
 background-color: #3C6255;
 border: 0;
}


.middleLayer_Navbar{
    height: 5rem;
    width: 90%;
    background-color: #3C6255;
}


