/* Font Styles */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap');

/* !------------------------------------- */


.aboutContainer{
    height: 75%;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.aboutContainer::-webkit-scrollbar {
    width: 0em;
  }

.aboutContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  }



.aboutHeader{
   color:#3C6255 ;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.aboutHeader p{
    text-align: justify;
    padding: 0px 1rem;
}

.aboutCardContainer{
     width: 100%;
     height: auto;
     display: flex;
     flex-direction: column;
}

.aboutCardContent{
    background-color: #A6BB8D;
    margin: 2px;
}

.aboutCardContent h1{
    text-align: center;
    font-size: 1.6rem;
    color: #3C6255;
    font-family: 'Montserrat', sans-serif;
}

.aboutCardContainer p{
    padding: 0px 10px ;
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
}

.aboutCardContainer li{
    list-style:square;
    font-family: 'Montserrat', sans-serif;
}

.aboutCardImage{
  height: 400px;
  width: 100%;
  background-color: antiquewhite;
}

.aboutCardImage img{
    height: 100%;
    width: 100%;
}


@media only screen and (min-width:768px) {
    .aboutCardContainer{
        flex-direction: row;
        margin: 5px;
    }

    .aboutCardContent{
        width: 70%;
        height: 50%;
        align-self: center;
        position:relative;
        left: 20px;
    }

    .aboutCardImage{
        width: 50%;
        height: 350px;
    }

}