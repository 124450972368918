/* Font Styles */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap');

/* !------------------------------------- */
.contactContainer{
    height: 75%;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.contactContainer::-webkit-scrollbar {
    width: 0em;
  }

.contactContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  }

.contactHeader{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: #3C6255;
    font-size: 1.5rem;
}  

.contactAdress{
    text-align: center;
    font-size: 1.2rem;
    padding: 0px 20px;
    font-family: 'Montserrat', sans-serif;
}

.contactPhone{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactPhone p{
    margin: 0;
    margin: 10px 0px;
}

.contactEmail{
    display: flex;
    flex-direction: column;
    align-items: center;
}
