.footer-container{
    background-color: #A6BB8D ;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
}

.footer-container span{
    margin: 0;
    padding: 0;
    font-size: 1rem;
    width: 80%;
}


.footer-container p{
    margin: 0;
    margin-top: 10px;
    padding: 0;
    width: 90%;
    font-size: 0.6rem;
    text-align: center;
}