/* Font Styles */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap');

/* !------------------------------------- */


.projectContainer{
    height: 75%;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.projectContainer::-webkit-scrollbar {
    width: 0em;
  }

.projectContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

}


.projectHeader{
    /* background-color: aquamarine; */
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.projectHeader h1{
    color: #3C6255;
    font-size: 2.5rem;
}

.projectHeader p{
    color: #3C6255;
    text-align: justify;
    padding: 0px 1rem;
}

.projectFeature{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.projectFeatureHeading{
    padding: 1rem;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    color: #3C6255;
}

.amenitieWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
}

.amenitieContainer{
    background-color: #A6BB8D;
    margin: 5px;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.amenitieImage{
 
    background-color: #3C6255;
    width: 75px;
    height: 75px;
}

.amenitieImage img{
    width: 100%;
    height: 100%;
}


.amenitieHeader{
     font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    color: black
}

.projectPriceBrochure{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
}

.priceBrochureHeader{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: #3C6255;
}

.priceBrochureHeader p{
    text-align: justify;
    padding: 0px 2rem;
    font-size: 1rem;
}

.formContainer{
    background-color: #A6BB8D;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}

.formWrapper{
    display: flex;
    flex-direction: column;
}

.formWrapper input{
    padding: 10px;
    width: 90%;
    margin:10px ;
    border-radius: 20px;
    border: 0;
    background-color: #3C6255;
    color: white; 
    font-size: 1rem;
}

.formContainer input::placeholder{
    color: white;
}

.formWrapper input:focus{
    outline: 0;
    background-color: #7b8b67;
}


.formWrapper select{
    padding: 10px;
    width: 90%;
    margin:10px ;
    border-radius: 20px;
    border: 0;
    background-color: #3C6255;
    color: white; 
}

.formWrapper button{
    margin-top: 2rem;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 0;
    background-color: #3C6255;
    color: white;
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
}

.downloadButtonContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
   
}

.downloadContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px;
    text-decoration: none;
}


.downloadContainer button{
     background-color: #3C6255;
     font-size: 1.4rem;
     border: 1px solid black;
     padding: 15px;
     color: white;
}

.downloadContainer button:hover{
    cursor: pointer;
}




@media only screen and (min-width:481px) {
    .projectFeature{
        align-items: inherit;
    }
    .amenitieWrapper{
        grid-template-columns: 1fr 1fr 1fr;
        place-items: center;
    }

    .amenitieContainer{
        width: 15rem;
    }

    .projectPriceBrochure{
        flex-direction: row;
        padding-bottom: 20px;
    }

    .priceBrochureHeader {
        width: 60%;
    }

    .priceBrochureHeader h1 {
         margin-top: 6rem;
    }

    .priceBrochureHeader p {
       width: 70%;
       margin-left: 10%;
   }

    .formContainer{
        padding-top: 2rem;
        width: 40%;
        border-radius: 20px;
    }

    .formWrapper{
        width: 100%;
    }

    .formWrapper button{
        width: 60%;
        margin-left: 15%;
    }

    .formWrapper input{
        width: 70%;
    }

    .downloadButtonContainer{
        margin-top: 0px;
    }


}
