/* Font Styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap');

/* !------------------------------------- */

.navBar-content{
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.navBar-content-left{
    width: 10%;
    height: 90%;
}

.navBar-content-left img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.navBar-content-right{
    width: 80%;
    height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   /* background-color: blue; */
}

.navBar-content-right-lg{
    width: 100%;
    height: 60%;
    background-color: rgb(255, 254, 253);
}

.navBar-content-right-lg a{
    text-decoration: none;
  }

.navBar-content-right-lg ul{
    margin: 0;
    display:  flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.navBar-content-right-lg li{
    text-decoration: none;
    color: black;
    list-style-type:none;
    font-family: 'Poppins', sans-serif;
}

.navBar-content-right-lg li:hover{
    color: #61876E;
}

.navBar-content-right-lg span:hover{
    color: #3C6255;
}

@media only screen and (max-width:480px) {
    .navBar-content{
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: auto;
    }
    .navBar-content-left{
        /* background-color: aqua; */
        width: 40%;
        margin-left: 20px;
    }

    .navBar-content-right-sm{
        /* background-color: aquamarine; */
        width: 40px;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .navBar-toggle{
        margin: 0;
        height: 5px;
        width: 60%;
        border-bottom: 3px solid white;
    }

    .navBar-toggle-cross45{
        margin: 0;
        height: 5px;
        width: 60%;
        border-bottom: 3px solid white;
        transform: rotate(45deg);
    }

    .navBar-toggle-cross225{
        margin: 0;
        height: 5px;
        width: 60%;
        border-bottom: 3px solid white;
        transform: rotate(125deg);
    }

    .toggleMenu{
        background-color: #3C6255;
        grid-column: 1/3;
        z-index: 1;
        display: 'none';
        height: auto;
        animation: extend 0.1s ease-in-out 1;
    }

    @keyframes extend {
        
        from{
           transform: translateY(-200px);
           height: 0%;
        } to{
            transform: translateY(0px);
            height: 100%;
        }

    }

    .toggleListName{
        display: flex;
        justify-content: center;
        border-right: 1px solid white;
        border-left: 1px solid white;
        margin: 1.5rem;
        padding: 1rem;
    }

    .toggleListName a{
        text-decoration: none;
        color: white;
        list-style: none;
    }

    .toggleListName li{
        font-size: 1.1rem;
        font-family: 'Raleway', sans-serif;
        font-weight: 400;
    }

}

