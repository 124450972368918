
  .enquiry-container{
    width: 100%;
    height: 20rem;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-image: url("../../media/banner.jpg");
    background-size: cover;
  }

  .enquiry-text{
    width: 50% ;
    height: 100%;
    /* background-color: aliceblue; */
    display: flex;
    flex-direction: column;
  }

  .enquiry-heading{
    margin: 2px;
    width: 96%;
    padding-left: 1rem;
    /* background-color: aquamarine; */
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
  }

  .enquiry-heading h1{
    margin: 0;
    padding: 0;
  }

  
  .enquiry-heading h3{
    margin: 0;
    padding: 0;
  }

  .enquiry-subheading{
    margin-top: 10px;
    padding-left: 1rem;
  }
  .enquiry-subheading h6{
    margin: 0;
    margin-top: 1rem ;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300 ;
  }

  .enquiry-subheading h4{
    margin: 0;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400 ;
  }

  .enquiry-subheading p{
    margin: 0;
    margin-top: 1rem;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800 ;
  }

  .enquiry-text span{
    margin-top: 1rem;
      font-size: 1.8rem;
      padding-left: 1rem;
      animation: flip 3s infinite;
  }

  @keyframes flip {
    0%{
      color: black;
    }50%{
      color: white;
    }100%{
      color: black;
    }
  }

  .enquiry-form{
    /* background-color: aquamarine; */
    width: 40%;
    height: 100%;
  }

  .enquiry-form form{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
  }

  .enquiry-form form>input{
    padding: .5rem;
    width: 70%;
    margin-left: 5%;
    font-size: 1rem;
    border-radius: 20px;
    border: 1px solid #3C6255;
    background-color: #3C6255;
    color: white;
  }

  .enquiry-form form>input:focus{
    border: 0;
    background-color: #A6BB8D;
  }

  .enquiry-form form>input::placeholder{
     color: white;
     font-size: 0.9rem;
  }

  .enquiry-form form>select{
    padding: .5rem;
    width: 70%;
    margin-left: 5% ;
    font-size: 1rem;
    border-radius: 20px;
    border: 0;
    background-color: #3C6255;
    color: white; 
}


  .enquiry-form form>textarea{
    width: 90%;
    margin-left: 5%;
    min-width: 10px;
    border-radius: 20px;
    padding-left: 20px;
    resize: none;
    background-color: #3C6255;
    color: white;  }

  .enquiry-form form>textarea::placeholder{
    color: white;
    font-size: 1rem;
  }

  .enquiry-form form>textarea:focus{
    background-color: #A6BB8D;
  }

  .enquiry-form form>button{
    margin-left: 5%;
    width: 30%;
    padding: 0.5rem 0rem;
    font-size: 1rem;
    border-radius: 20px;
    border: 1px solid #3C6255;
    background-color: #A6BB8D;
    color: white;
  }

  .enquiry-form form>button:hover{
    background-color: #3C6255;
    cursor: pointer;
  }

  @media only screen and (max-width:480px) {
    .enquiry-container{
      flex-direction: column;
      height: auto;
    }
  
    .enquiry-text{
      width: 100%;
      margin-bottom: 1rem;
    }
  
     .enquiry-heading h1{
      font-size: 2rem;
    }
    .enquiry-heading h3{
      font-size: 1rem;
    }
  
    .enquiry-subheading h4{
      font-size: 1.6rem;
    }
    .enquiry-subheading h6{
      font-size: 1.2rem;
    }
    .enquiry-subheading p{
      font-size: 1.2rem;
    }
  
    .enquiry-text span{
      font-size: 1.5rem;
    }
  
    .enquiry-form{
      width: 100%;
      padding-bottom: 1rem;
    }
  
    .enquiry-form>form>input{
      margin: 0.3rem;
    }

    .enquiry-form>form>select{
      margin: 0.3rem;
    }

    .enquiry-form>form>textarea{
      margin: 0.3rem;
    }
    .enquiry-form form >button{
      width: 70%;
      margin-left: 15%;
    }
}